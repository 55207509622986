
import "@/assets/styles/imports/email-success.sass"
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class TheEmailSuccess extends Vue {
  get email (): string {
    return typeof this.$route.query.email === 'string' ? this.$route.query.email : ''
  }
}
