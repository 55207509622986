
import { Component, Vue } from 'vue-property-decorator'
import TheEmailSuccess from '@/components/email-success/TheEmailSuccess.vue'

@Component({
  components: {
    TheEmailSuccess
  },
  metaInfo() {
    return{
    title: this.$tc('caption'),
  }}
})
export default class EmailSuccessPage extends Vue {

}
